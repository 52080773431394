<template>
  <div id="news-post" class="news__container">
    <!-- BANNER -->
    <banner-single>
      <template #image>
        <div
          class="bannerbox__image d-none d-md-block"
          :style="{ backgroundImage: 'url(' + newsPost.Img + ')' }"
        ></div>
        <div
          class="bannerbox__image d-block d-md-none"
          :style="{ backgroundImage: 'url(' + newsPost.MImg + ')' }"
        ></div>
      </template>
      <template #mark>
        <img
          src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-white.svg"
          class="banner__mark img-fluid"
        />
      </template>
    </banner-single>

    <SocialShare />

    <!-- MAIN -->
    <article class="bg-none">
      <!-- SECTION_1 -->
      <section class="sec">
        <img
          src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-third.svg"
          class="img-fluid sec-deco-bottom"
          style="margin-bottom: -1px;"
        />
        <h2 class="sec__title"></h2>
        <div class="sec__content container">
          <div class="intro-box">
            <div class="intro-bar d-flex justify-content-between">
              <span class="intro-type"><span class="deco-border"></span>{{ newsPost.NewsClass }}</span
              ><time class="intro-date font-enUS">{{ $filters.dateFormat(newsPost.Date) }}</time>
            </div>
            <h1 class="post-title">{{ newsPost.Title }}</h1>
            <p>{{ newsPost.SubTitle }}</p>
          </div>

          <div class="custom-content">
            <div class="row">
              <div
                class="col-lg-10 offset-lg-1"
                v-html="newsPost.Content"
              ></div>
            </div>
          </div>
          <div class="attachment-wrap" v-if="newsPost.HciFiles !== null">
            <div class="contanier">
              <div class="row">
                <div class="col-lg-10 offset-lg-1">
                  <h2 class="attachment__title">附件</h2>
                  <ul class="attachment__list">
                    <li v-for="(item, index) in newsPost.HciFiles" :key="index">
                      <span class="py-3">{{ item.Title }}</span>
                      <a
                        :href="item.FileLink"
                        class="
                          btn btn-pill btn-primary
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                        :download="item.Title"
                        >下載<i class="icon icon-download"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SECTION_2 -->
      <section class="related__block bg-middle" style="padding-bottom: 8rem">
        <div class="container">
          <div class="row pt-3 pt-md-5 pd-2 pb-md-3">
            <div class="col-12">
              <div class="relatedbar">
                <h2 class="relatedbar__title">相關消息</h2>
                <router-link
                  :to="'/' + this.$route.params.lang + '/news'"
                  @click="clickRouterTop"
                  class="relatedbar__link d-none d-md-block"
                  >回訊息列表<span class="icon-back"><span></span><span></span></span></router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="related__list">
          <slider-fluid :cardList="newsList" :currentRoute="currentRoute"></slider-fluid>
        </div>
          <div class="row pb-3 d-block d-md-none">
            <div class="col-12">
              <div class="relatedbar">
                <router-link
                  :to="'/' + this.$route.params.lang + '/news'"
                  @click="clickRouterTop"
                  class="relatedbar__link"
                  >回訊息列表<span class="icon-back"><span></span><span></span></span></router-link
                >
              </div>
            </div>
          </div>
      </section>
    </article>
  </div>
</template>

<script>
import axios from "axios";

import BannerSingle from "@/components/BannerSingle.vue";
import SocialShare from "@/components/SocialShare.vue";
import SliderFluid from "@/components/SliderFluid.vue";

export default {
  components: {
    BannerSingle,
    SocialShare,
    SliderFluid,
  },
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      currentRoute: "news",
      currentURL: 'https://www.hci.org.tw/zh-TW/news/'+this.$route.params.id,
      newsPost: [],
      newsList: [],
    };
  },
  created() {
    this.newsP();
    this.newsL();

    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currentUrlName = (this.currentRoute+'/'+this.$route.params.id).toLowerCase();
      console.log("currentUrlName, this.$route====>", currentUrlName, this.$route);
      console.log("seoData====>", res.data.data);
      this.seoData = res.data.data.filter(item => (item.SetPage == currentUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;

      console.log("this.seoData.setPage ====>", this.seoData[0].SetPage);

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sTitle);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });
  },
  watch: {
    '$route' () { 
      this.newsP();
      this.newsL();
    }
  },
  methods:{
    newsP() {
      var FD = new FormData();
      FD.append("mode", "raw_sct");
      FD.append(
        "data",
        '{"class":"HciNews","lang":"zh-TW","key":"SeoUrl","val":"' +
          this.$route.params.id +
          '"}'
      );
      axios({
        method: "post",
        url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
        data: FD,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        this.newsPost = res.data.data[0];

        //v-html run script
        const scriptUrls = ["https://kinpoweb.blob.core.windows.net/hci21/frontend/script/jquery.min.js",
          "https://kinpoweb.blob.core.windows.net/hci21/frontend/script/splide@4.0.7.min.js",
          "https://kinpoweb.blob.core.windows.net/hci21/frontend/script/custom.js"];
        for (const url of scriptUrls) {
          const scriptEl = document.createElement('script');
          scriptEl.src = url;
          document.body.appendChild(scriptEl);
        }
      });
    },
    newsL() {
      var FD2 = new FormData();
      FD2.append("mode", "raw_sct");
      FD2.append("data", '{"class":"HciNews","lang":"zh-TW","sw":"1"}');
      axios({
        method: "post",
        url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
        data: FD2,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        this.newsList = res.data.data.filter(item => (item.SeoUrl!==this.$route.params.id));
        console.log(this.$route.params.id);
        console.log("newsList", this.newsList);
      });
    },
    clickRouterTop(){
      //chrome
      document.body.scrollTop = 0
      //firefox
      document.documentElement.scrollTop = 0
      //safari
      window.pageYOffset = 0
    },
  }
};
</script>
<style lang="scss">
</style>